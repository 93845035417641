.Body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    height: calc(100vh- 20rem);
    background-repeat: no-repeat;
    background-position: center center;
  }

  .Card {
    padding: 16px 8px;
    width: 407px;
    border-radius: 6px;
    border: none;
  }