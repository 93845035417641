.App{
  background-color: #fafbfc;
}


.Card {
  width: 407px;
  border-radius: 6px;
  border: none;
  padding: 26px 0px;
}